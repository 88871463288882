(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

var _appCarousel = _interopRequireDefault(require("./app/appCarousel"));

var _appMainMenu = _interopRequireDefault(require("./app/appMainMenu"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

document.addEventListener('DOMContentLoaded', function () {
  (0, _appCarousel["default"])();
  var mainNavItem = $('.has-dropdown');
  var subMenu = $('.sub-nav');
  var mobileNavButton = $(".mobile__nav-button");
  var mobileMenu = $('.mobile__menu');
  var mobileSubMenuItemPsiko = $('.dropdown a.psiko');
  var mobileSubMenuItemTedavi = $('.dropdown a.tedavi');
  var mobileSubMenuItemArticle = $('.dropdown a.article');
  var mobileSubMenuPsiko = $('.dropdown-menu-psiko');
  var mobileSubMenuTedavi = $('.dropdown-menu-tedavi');
  var mobileSubMenuArticles = $('.dropdown-menu-articles');
  var mobileSubMenuBackLink = $('.dropdown-menu__back-link');
  $(mainNavItem).hover(function () {
    var dropdown = $(this).find(subMenu);
    dropdown.addClass("is-active");
  }, function () {
    subMenu.removeClass("is-active");
  });
  $(mobileNavButton).on("click", function (e) {
    if (!mobileNavButton.hasClass("active")) {
      mobileNavButton.addClass("active");
      mobileMenu.addClass("active");
      $('html, body').addClass('noscroll');
      $('.main-mobile-login').removeClass('open');
    } else {
      mobileNavButton.removeClass("active");
      mobileMenu.removeClass("active");
      mobileSubMenuPsiko.removeClass("active");
      mobileSubMenuTedavi.removeClass("active");
      $('html, body').removeClass('noscroll');
    }
  });
  $(mobileSubMenuItemPsiko).on('click', function (e) {
    if (!mobileSubMenuPsiko.hasClass("active")) {
      mobileSubMenuPsiko.addClass('active');
    }
  });
  $(mobileSubMenuItemTedavi).on('click', function (e) {
    if (!mobileSubMenuTedavi.hasClass("active")) {
      mobileSubMenuTedavi.addClass('active');
    }
  });
  $(mobileSubMenuItemArticle).on('click', function (e) {
    if (!mobileSubMenuArticles.hasClass("active")) {
      mobileSubMenuArticles.addClass('active');
    }
  });
  $(mobileSubMenuBackLink).on('click', function (e) {
    if (mobileSubMenuPsiko.hasClass('active')) {
      mobileSubMenuPsiko.removeClass('active');
    } else if (mobileSubMenuTedavi.hasClass('active')) {
      mobileSubMenuTedavi.removeClass('active');
    } else if (mobileSubMenuArticles.hasClass('active')) {
      mobileSubMenuArticles.removeClass('active');
    }
  });
});

},{"./app/appCarousel":2,"./app/appMainMenu":3}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;

function _default() {
  var slider = $('#main_slider');
  slider.owlCarousel({
    items: 1,
    nav: false,
    autoplay: true,
    autoplayTimeout: 5000,
    loop: true,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn'
  });
}

},{}],3:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _this = void 0;

var _default = function _default() {
  var mainNavItem = $('.has-dropdown');
  var subMenu = $('.sub-nav');
  $(mainNavItem).hover(function () {
    var dropdown = $(_this).find(subMenu);
    dropdown.addClass("is-active");
  }, function () {
    subMenu.removeClass("is-active");
  });
};

exports["default"] = _default;

},{}]},{},[1]);
